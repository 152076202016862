<template>
    <div class="custom-container" :class="[ fullSidebar ? 'sidebar-full' : 'sidebar-thin' ]">

        <TheSidebar :fullSize="fullSidebar"/>

        <ThePageHeadingBar
            :pageHeading="pageHeading"
            :fullSize="fullSidebar"
        />

        <section class="main">
            <!-- Reassignment Modal -->
            <ModalReassignSelectedTitles
                v-if="showReassignmentModal"
                :selectedTitles="assignedView.selected"
                @close="hideReassignmentModal()"
                @reassigned="getTitleList()"
            />

            <!-- Top Page Information -->
            <div class="page-info-top page-info-top-bottom-padding result-count-bottom-border">
                <div
                    v-if="isViewUnassigned"
                    class="context-information"
                >
                    <h2>{{ titleList.length }} Results</h2>
                        <ToolTip>
                            <p slot="target"><i class="material-icons help">help</i></p>
                            <p slot="content" style="width:400px">
                                These are titles that meet the following criteria:<br />
                                1. The last researched date is outside of last researched threshold
                                (i.e. if the last researched threshold is 60 and it was researched 70 days ago,
                                it would show up here, or if the title has never been researched.)<br />
                                2. The title has a catalog listing where status = NEW AND the section start date is in the active title research period.
                                <br/> <br/>
                                ( {{ unassignedTitleList.length }} - Unassigned ) ( {{ assignedTitleList.length }} - Assigned )
                            </p>
                        </ToolTip>
                </div>

                <!-- View titles by assignment status buttons -->
                <span class="view-by-button-alignment">
                    <button
                        class="view-by-button-default"
                        :class="[ isViewAssigned ? 'view-by-button-active' : '' ]"
                        @click="setCurrentView( viewBy.assigned )"
                    >
                        Assigned
                    </button>
                    <button
                        class="view-by-button-default"
                        :class="[ isViewUnassigned ? 'view-by-button-active' : '' ]"
                        @click="setCurrentView( viewBy.unassigned )"
                    >
                        Unassigned
                    </button>
                </span>
            </div>

            <!-- Unassigned Titles List -->
            <div v-if="isViewUnassigned">
                <LoaderIcon v-if="inFlight"/>
                <div v-else>
                    <TableBaseFilterable
                        class="title-research-list-table"
                        v-bind.sync="unassignedView"
                        :headers="sharedView.headers"
                        :items="unassignedTitleList"
                        :itemsPerPage="-1"
                        cardElevation="0"
                        showSelect
                        hideAdd
                        hide-filter
                    >
                        <template #[`item.description`]="{ item }">
                            {{ item.description || 'Not Set' }}
                        </template>
                        <template #[`item.primarySupplier`]="{ item }">
                            {{ item.primarySupplier || 'Not Set' }}
                        </template>
                        <template #[`item.shelfDate`]="{ item }">
                            {{ FormatUtil.yearMonthDayToSimpleString( item.shelfDate ) }}
                        </template>
                        <template #[`item.sectionStartDate`]="{ item }">
                            {{ FormatUtil.dateTimeToSimpleString( item.sectionStartDate ) }}
                        </template>
                        <template #[`item.listingCreateDate`]="{ item }">
                            {{ FormatUtil.dateTimeToSimpleString( item.listingCreateDate ) }}
                        </template>
                        <template #[`item.lastResearchedDate`]="{ item }">
                            {{ FormatUtil.dateTimeToSimpleString( item.lastResearchedDate ) || "Never Researched" }}
                        </template>
                        <template #[`item.listingStatus`]="{ item }">
                            {{ item.listingStatus || 'NEW' }}
                        </template>
                    </TableBaseFilterable>
                </div>
            </div>

            <!-- Assigned Titles By Researcher List -->
            <div v-if="isViewAssigned">
                <LoaderIcon v-if="inFlight"/>
                <template v-else>
                    <div
                        v-for="researcher in uniqueResearchers"
                        :key="researcher"
                        class="title-research-list-table"
                    >
                        <h2>{{ researcher }} ( {{ getResearcherTitleList( researcher ).length }} )</h2>
                        <TableBaseFilterable
                            :headers="sharedView.headers"
                            :items="getResearcherTitleList( researcher )"
                            :itemsPerPage="150"
                            v-bind.sync="assignedView"
                            cardElevation="3"
                            showSelect
                            hideAdd
                        >
                            <template #[`item.description`]="{item}">
                                {{ item.description || 'Not Set' }}
                            </template>
                            <template #[`item.primarySupplier`]="{item}">
                                {{ item.primarySupplier || 'Not Set' }}
                            </template>
                            <template #[`item.shelfDate`]="{ item }">
                                {{ FormatUtil.yearMonthDayToSimpleString( item.shelfDate ) }}
                            </template>
                            <template #[`item.sectionStartDate`]="{ item }">
                                {{ FormatUtil.dateTimeToSimpleString( item.sectionStartDate ) }}
                            </template>
                            <template #[`item.listingCreateDate`]="{ item }">
                                {{ FormatUtil.dateTimeToSimpleString( item.listingCreateDate ) }}
                            </template>
                            <template #[`item.lastResearchedDate`]="{ item }">
                                {{ FormatUtil.dateTimeToSimpleString( item.lastResearchedDate ) || "Never Researched" }}
                            </template>
                            <template #[`item.listingStatus`]="{ item }">
                                {{ item.listingStatus || 'NEW' }}
                            </template>
                        </TableBaseFilterable>
                    </div>

                </template>
            </div>

            <!-- Page Footer Bar -->
            <ThePageFooterBar>

                <!-- Unassigned titles content -->
                <div
                    v-if="isViewUnassigned"
                    class="title-assignment"
                >
                    <!-- Assign X Titles-->
                    <label for="numberOfTitlesToAssign">
                        Assign
                    </label>
                    <v-text-field
                        id="numberOfTitlesToAssign"
                        v-model.number="unassignedSelectedCount"
                        :disabled="unassignedView.inFlight"
                        type="number"
                        class="number-of-titles"
                        dense
                        hideDetails
                    />
                    <span>titles to</span>

                    <!-- Assignee Selection Dropdown -->
                    <DropdownSelectAssignee
                        v-model="assignee"
                        :disabled="unassignedView.inFlight"
                    />
                    <!-- Assign Selected Books Button -->
                    <v-btn
                        :disabled="inFlight || !isReadyToAssign || unassignedView.inFlight"
                        color="primary"
                        @click="assignSelectedTitles()"
                    >
                        Assign
                    </v-btn>
                </div>

                <!-- Assigned titles content -->
                <span v-if="isViewAssigned" class="title-modify-assingment">
                    <!-- Unassign Selected Books Button -->
                    <v-btn
                        :disabled="inFlight || !isReadyToUnassign"
                        color="primary"
                        @click="unassignSelectedTitles()"
                    >
                        Unassign
                    </v-btn>

                    <!-- Reassign Selected Books Button -->
                    <v-btn
                        :disabled="inFlight || !isReadyToUnassign"
                        outlined
                        @click="displayReassignmentModal()"
                    >
                        Reassign
                    </v-btn>

                </span>
            </ThePageFooterBar>
        </section>
    </div>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Libraries
import _ from 'lodash';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Components
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import ThePageFooterBar from '@/components/ThePageFooterBar';
import TheSidebar from '@/views/common/TheSidebar';
import LoaderIcon from '@/components/base/loaders/LoaderIcon';
import ToolTip from '@/components/base/tooltip/ToolTip';
import DropdownSelectAssignee from '@/components/dropdowns/DropdownSelectAssignee';
import ModalReassignSelectedTitles from '@/components/modals/ModalReassignSelectedTitles';
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

export default {
    name: 'TitleAssignment',
    components: {
        ThePageHeadingBar,
        ThePageFooterBar,
        TheSidebar,
        LoaderIcon,
        ToolTip,
        DropdownSelectAssignee,
        ModalReassignSelectedTitles,
        TableBaseFilterable,
    },
    data() {
        return {
            FormatUtil,
            assignedView: {
                selected: [],
                filter: '',
            },
            unassignedView: {
                inFlight: false,
                selected: [],
                filter: '',
            },
            sharedView: {
                headers: [
                    {
                        text: 'EAN',
                        value: 'productCode',
                    },
                    {
                        text: 'Title',
                        value: 'description',
                    },
                    {
                        text: 'Primary Supplier',
                        value: 'primarySupplier',
                    },
                    {
                        text: 'Shelf Date',
                        value: 'shelfDate',
                    },
                    {
                        text: 'Section Start Date',
                        value: 'sectionStartDate',
                    },
                    {
                        text: 'Listing Create Date',
                        value: 'listingCreateDate',
                    },
                    {
                        text: 'Last Researched Date',
                        value: 'lastResearchedDate',
                    },
                    {
                        text: 'Listing Status',
                        value: 'listingStatus',
                    },
                ],
            },
            fullSidebar: false,
            pageHeading: 'Title Assignment',

            showReassignmentModal: false,

            assignee: {},

            currentView: '',
            viewBy: {
                assigned: 'assigned',
                unassigned: 'unassigned',
            },
        };
    },
    computed: {
        ...mapGetters( {
            formattedTitleList: 'Title/Assignment/formattedList',
            assignedTitleList: 'Title/Assignment/assignedList',
            unassignedTitleList: 'Title/Assignment/unassignedList',
            uniqueResearchers: 'Title/Assignment/uniqueResearchers',
        } ),
        ...mapState( {
            titleList: ( state ) => state.Title.Assignment.list,
            inFlight: ( state ) => state.Title.Assignment.inFlight,
        } ),
        /** @returns { Boolean } */
        isViewAssigned() {
            return this.currentView === this.viewBy.assigned;
        },
        /** @returns { Boolean } */
        isViewUnassigned() {
            return this.currentView === this.viewBy.unassigned;
        },

        /** @returns { Boolean } */
        isReadyToAssign() {
            return !!this.unassignedView.selected.length
                && !_.isEmpty( this.assignee );
        },
        /** @returns { Boolean } */
        isReadyToUnassign() {
            return this.assignedView.selected.length > 0;
        },

        /** @returns { Number } */
        unassignedSelectedCount: {
            get() {
                return this.unassignedView.selected.length;
            },
            set( value ) {
                if ( value > 0 ) {
                    if ( value >= this.unassignedTitleList.length ) {
                        // only allow as many titles as are unassigned to be selected
                        this.unassignedView.selected = this.unassignedTitleList;
                    } else {
                        this.unassignedView.selected = this.unassignedTitleList.slice( 0, value );
                    }
                } else {
                    this.unassignedView.selected = [];
                }
            },
        },
    },
    beforeMount() {
        this.currentView = this.viewBy.unassigned;
        this.getTitleList();
    },
    methods: {
        /** @returns { Array } */
        getResearcherTitleList( researcher ) {
            return this.assignedTitleList.filter( ( title ) => title.researcherName === researcher );
        },
        displayReassignmentModal() {
            this.showReassignmentModal = true;
        },
        hideReassignmentModal() {
            this.showReassignmentModal = false;
        },
        setCurrentView( viewOption ) {
            this.currentView = viewOption;
        },

        async unassignSelectedTitles() {
            try {
                await this.$store.dispatch( 'Title/Assignment/unassignTitleList', {
                    titleList: this.assignedView.selected,
                } );
                this.getTitleList();
            } catch ( error ) {
                console.log( 'Error in unassignSelectedTitles: ', error );
            }
        },
        async assignSelectedTitles() {
            this.unassignedView.inFlight = true;
            try {
                await this.$store.dispatch( 'Title/Assignment/assignTitleList', {
                    titleList: this.unassignedView.selected,
                    assignee: this.assignee?.username,
                } );
                this.unassignedView.selected = [];
                this.getTitleList();
            } catch ( error ) {
                console.log( 'Error in assignSelectedTitles: ', error );
            } finally {
                this.unassignedView.inFlight = false;
            }
        },
        async getTitleList() {
            // Reset local data
            this.assignedView.selected = [];
            this.unassignedView.selected = [];
            this.assignee = {};

            // Refresh the Title/Assignment/list and Title/Queue/list after we've assigned titles
            await this.$store.dispatch( 'Title/Assignment/getList' );
        },
    },
};
</script>

<style scoped lang="scss">
    @import "@/assets/sass/variables.scss";
    @import "@/assets/sass/assignment.scss";

    .title-research-list-table {
        margin-bottom: 4rem;

        &:last-of-type {
            margin-bottom: 10rem;
        }
    }

    .title-assignment {
        display: flex;
        align-items: center;
        * {
            margin-right: .5rem;

        }
        .number-of-titles {
            width: 4rem;
        }

        .assignment-button {
            padding: 10px;
        }
    }
    .title-modify-assingment {
        * {
            margin-right: 1rem;
        }
    }
    main{
        grid-area: main;
    }

    .view-by-button-alignment {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        flex: 1 100%;
    }

    .view-by-button-default {
        background-color: transparent;
        border-color: transparent;
        font-size: 14px;
        padding: 10px;
        color: $assignment-button-fg;
        position: relative;
        letter-spacing: 1.25px;
        border-bottom: solid 2px transparent;
        border-radius: 0;
        box-shadow: none;
        transform: none;
    }

    .view-by-button-default:focus {
        outline: none;
    }

    .view-by-button-alignment button:hover:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }

    .view-by-button-alignment button:hover {
        cursor: pointer;
    }

    .view-by-button-active {
        outline: none;
        border-bottom: solid 2px $assignment-button-border;
    }

    .page-info-top {
        padding: 0;
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
    }

    .page-info-top-bottom-padding {
        margin-bottom: 40px;
    }

    .context-information {
        display: inline-flex;
        flex: 1 100%;
        margin-bottom: -15px;
    }

    .context-information h2 {
        color: $assignment-h2-fg;
        margin: 0;
    }

    .help {
        color: $assignment-help-fg;
        margin-left: 5px;
        transform: translateY(10%);
    }
</style>
